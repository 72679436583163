import React, { useState, useEffect, createContext, useContext } from "react"
import { getUser } from "../services/user/user"

export const UserContext = createContext({})

export const UserContextProvider = (props) => {
  const [user, setUser] = useState()
  const [userAddresses, setUserAddresses] = useState(null)
  const [userOrders, setUserOrders] = useState(null)
  const [userPaymentMethods, setUserPaymentMethods] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (isLoading && !user) {
      getUser()
        .then((user) => {
          if (user) {
            setUser(user)
          } else {
            setUser(null)
          }
          setIsLoading(false)
        })
        .catch((e) => {
          setUser(null)
          setIsLoading(true)
          console.error(e)
        })
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userAddresses,
        setUserAddresses,
        setUserOrders,
        userOrders,
        userPaymentMethods,
        setUserPaymentMethods,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export const wrapRootElement = ({ element }) => (
  <UserContextProvider>{element}</UserContextProvider>
)

export const useUserContext = () => {
  return useContext(UserContext)
}
