// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-addresses-js": () => import("./../../../src/pages/account/addresses.js" /* webpackChunkName: "component---src-pages-account-addresses-js" */),
  "component---src-pages-account-containers-js": () => import("./../../../src/pages/account/containers.js" /* webpackChunkName: "component---src-pages-account-containers-js" */),
  "component---src-pages-account-forgot-password-js": () => import("./../../../src/pages/account/forgot-password.js" /* webpackChunkName: "component---src-pages-account-forgot-password-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("./../../../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-payment-js": () => import("./../../../src/pages/account/payment.js" /* webpackChunkName: "component---src-pages-account-payment-js" */),
  "component---src-pages-account-referafriend-js": () => import("./../../../src/pages/account/referafriend.js" /* webpackChunkName: "component---src-pages-account-referafriend-js" */),
  "component---src-pages-account-register-js": () => import("./../../../src/pages/account/register.js" /* webpackChunkName: "component---src-pages-account-register-js" */),
  "component---src-pages-account-reset-password-js": () => import("./../../../src/pages/account/reset-password.js" /* webpackChunkName: "component---src-pages-account-reset-password-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-collect-js": () => import("./../../../src/pages/collect.js" /* webpackChunkName: "component---src-pages-collect-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cook-js": () => import("./../../../src/pages/cook.js" /* webpackChunkName: "component---src-pages-cook-js" */),
  "component---src-pages-delivery-zones-js": () => import("./../../../src/pages/delivery-zones.js" /* webpackChunkName: "component---src-pages-delivery-zones-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-how-it-works-js": () => import("./../../../src/pages/old-how-it-works.js" /* webpackChunkName: "component---src-pages-old-how-it-works-js" */),
  "component---src-pages-oldindex-js": () => import("./../../../src/pages/oldindex.js" /* webpackChunkName: "component---src-pages-oldindex-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-subscribe-to-calendar-js": () => import("./../../../src/pages/subscribe-to-calendar.js" /* webpackChunkName: "component---src-pages-subscribe-to-calendar-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-cooking-instructions-js": () => import("./../../../src/templates/cookingInstructions.js" /* webpackChunkName: "component---src-templates-cooking-instructions-js" */),
  "component---src-templates-in-person-shop-js": () => import("./../../../src/templates/in-person-shop.js" /* webpackChunkName: "component---src-templates-in-person-shop-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/productCategory.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-shopcopy-js": () => import("./../../../src/templates/shopcopy.js" /* webpackChunkName: "component---src-templates-shopcopy-js" */),
  "component---src-templates-tag-cooking-js": () => import("./../../../src/templates/tagCooking.js" /* webpackChunkName: "component---src-templates-tag-cooking-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

