const accountProvider = require("../swell/user")
const paymentProvider = require("../swell/payments")
const { camelToSnake } = require("../core/stringHelper")
const { isBrowser } = require("../browser")

const getResetUrl = (email) => {
  let baseUrl = process.env.WEBSITE_URL
  let resetUrl = `${baseUrl}/account/reset-password/?email=${email}&key={reset_key}`

  if (!baseUrl && isBrowser) {
    const origin = window.location.origin
    resetUrl = `${origin}/account/reset-password/?email=${email}&key={reset_key}`
  }
  return resetUrl
}

const getUser = async () => {
  return await accountProvider.getUser()
}

const loginUser = async (email, password) => {
  return await accountProvider.loginUser(email, password)
}

const registerUser = async (dataObj) => {
  const data = {}
  const objectKeys = Object.keys(dataObj)

  for (let i = 0; i < objectKeys.length; i++) {
    const objectKey = objectKeys[i]

    data[camelToSnake(objectKey)] = dataObj[objectKey]
  }

  return await accountProvider.registerUser(data)
}

const signOutUser = async () => {
  return await accountProvider.signOutUser()
}

const sendRecoverEmail = async (email, resetURL) => {
  return await accountProvider.sendRecoverEmail(email, resetURL)
}

const resetPassword = async (password, resetKey) => {
  return await accountProvider.resetPassword(password, resetKey)
}

const updateUserData = async (dataObj) => {
  const data = {}
  const objectKeys = Object.keys(dataObj)

  for (let i = 0; i < objectKeys.length; i++) {
    const objectKey = objectKeys[i]

    data[camelToSnake(objectKey)] = dataObj[objectKey]
  }

  return await accountProvider.updateUserData(data)
}

const getAddresses = async () => {
  return await accountProvider.getAddresses()
}

const createAddress = async (dataObj) => {
  const data = {}
  const objectKeys = Object.keys(dataObj)

  for (let i = 0; i < objectKeys.length; i++) {
    const objectKey = objectKeys[i]

    data[camelToSnake(objectKey)] = dataObj[objectKey]
  }

  if (!data.name && data.first_name && data.last_name) {
    data.name = `${data.first_name} ${data.last_name}`
    delete data.first_name
    delete data.last_name
  }
  return await accountProvider.createAddress(data)
}

const deleteAddress = async (id) => {
  return await accountProvider.deleteAddress(id)
}

const getOrders = async (limit = 10, page = 1) => {
  return await accountProvider.getOrders({limit: limit, page: page})
}

const getCards = async () => {
  return await accountProvider.getCards()
}

const createCard = async (dataObj) => {
  const data = {}
  const errors = []
  const objectKeys = Object.keys(dataObj)

  if (!paymentProvider.validateCardNumber(dataObj.number)) {
    errors.push({error: "Invalid card number"})
  }

  if (!paymentProvider.validateCardExpiration(`${dataObj.expMonth}/${dataObj.expYear}`)) {
    errors.push({error: "Invalid expiration date"})
  }

  if (!paymentProvider.validateCvc(dataObj.cvc)) {
    errors.push({error: "Invalid CVC"})
  }

  if (errors.length > 1) {
    return {validationErrors: errors}
  }

  dataObj.expMonth = parseInt(dataObj.expMonth)
  dataObj.expYear = parseInt(dataObj.expYear)
  dataObj.cvc = parseInt(dataObj.cvc)

  for (let i = 0; i < objectKeys.length; i++) {
    const objectKey = objectKeys[i]

    data[camelToSnake(objectKey)] = dataObj[objectKey]
  }

  const tokenizedData = await paymentProvider.createCardToken(data)

  return await accountProvider.createCard(tokenizedData.token)
}

const updateCardBillingAddress = async (cardId, billingAddress) => {
  const data = {
    billing: billingAddress
  }
  return await accountProvider.updateCard(cardId, data)
}

const deleteCard = async (id) => {
  return await accountProvider.deleteCard(id)
}

const getOrderDetails = async(id) => {
  return await accountProvider.getOrderDetails(id)
}

module.exports = {
  getUser: getUser,
  loginUser: loginUser,
  registerUser: registerUser,
  signOutUser: signOutUser,
  sendRecoverEmail: sendRecoverEmail,
  resetPassword: resetPassword,
  updateUserData: updateUserData,
  getAddresses: getAddresses,
  createAddress: createAddress,
  deleteAddress: deleteAddress,
  getOrders: getOrders,
  getOrderDetails: getOrderDetails,
  getResetUrl: getResetUrl,
  getCards: getCards,
  createCard: createCard,
  updateCardBillingAddress: updateCardBillingAddress,
  deleteCard: deleteCard,
}
