const swell = require("swell-js")

const options = {
  useCamelCase: false,
}

swell.init(process.env.SWELL_STORE_ID, process.env.SWELL_PUBLIC_KEY, options)

const createCardToken = async (dataObj) => {
  return await swell.card.createToken(dataObj)
}

const validateCardNumber = (cardNumber) => {
  return swell.card.validateNumber(cardNumber)
}

const validateCardExpiration = (expirationMonth, expirationYear) => {
  return swell.card.validateExpiry(expirationMonth, expirationYear)
}

const validateCvc = (cvc) => {
  return swell.card.validateCVC(cvc)
}

module.exports = {
  createCardToken: createCardToken,
  validateCardNumber: validateCardNumber,
  validateCardExpiration: validateCardExpiration,
  validateCvc: validateCvc,
}