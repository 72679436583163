const swell = require("swell-js")

const options = {
  useCamelCase: false,
}

swell.init(process.env.SWELL_STORE_ID, process.env.SWELL_PUBLIC_KEY, options)

const getUser = async () => {
  return await swell.account.get()
}

const loginUser = async (email, password) => {
  return await swell.account.login(email, password)
}

const registerUser = async (dataObj) => {
  return await swell.account.create(dataObj)
}

const signOutUser = async () => {
  return await swell.account.logout()
}

const sendRecoverEmail = async (email, resetURL) => {
  await swell.account.recover({ email: email, reset_url: resetURL })
}

const updateUserData = async (dataObj) => {
  return await swell.account.update(dataObj)
}

const resetPassword = async (password, resetKey) => {
  return await swell.account.recover({
    password: password,
    reset_key: resetKey,
  })
}

const getAddresses = async () => {
  return await swell.account.listAddresses({
    limit: 10,
    page: 1,
  })
}

const createAddress = async (dataObj) => {
  return await swell.account.createAddress(dataObj)
}
const deleteAddress = async (id) => {
  return await swell.account.deleteAddress(id)
}

const getOrders = async (data) => {
  return await swell.account.listOrders(data)
}

const getCards = async () => {
  return await swell.account.listCards({
    limit: 10,
    page: 1,
  })
}

const createCard = async (token) => {
  return await swell.account.createCard({
    token: token,
  })
}

const deleteCard = async (id) => {
  return await swell.account.deleteCard(id)
}

const updateCard = async (id, data) => {
  return await swell.account.updateCard(id, data)
}

const getOrderDetails = async (id) => {
  return await swell.account.getOrder(id)
}

module.exports = {
  getUser: getUser,
  loginUser: loginUser,
  registerUser: registerUser,
  signOutUser: signOutUser,
  sendRecoverEmail: sendRecoverEmail,
  updateUserData: updateUserData,
  resetPassword: resetPassword,
  getAddresses: getAddresses,
  createAddress: createAddress,
  deleteAddress: deleteAddress,
  getOrders: getOrders,
  getCards: getCards,
  createCard: createCard,
  deleteCard: deleteCard,
  updateCard: updateCard,
  getOrderDetails: getOrderDetails,
}
