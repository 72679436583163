const camelToSnake = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

const camelToSentenceCase = (string) => {
  let result = string.replace(/([A-Z])/g, " $1")

  return result.charAt(0).toUpperCase() + result.slice(1)
}

const splitCamelCaseToWords = (string) => {
  return string.replace(/([a-z])([A-Z])/g, "$1 $2")
}

module.exports = {
  splitCamelCaseToWords: splitCamelCaseToWords,
  camelToSentenceCase: camelToSentenceCase,
  camelToSnake: camelToSnake,
}
